import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import {
  MatToolbarModule,
  MatListModule,
  MatIconModule,
  MatSidenavModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule
} from '@angular/material';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { MainNavComponent } from './main-nav/main-nav.component';

import {
  NavigationModule,
  JwtInterceptorService
} from '@atonix/atx-navigation';
import { environment } from '../environments/environment';

// This app really does not need routing, however, many of the applications that
// will be using this component will use it to change routes in the app.  So
// we have routing here so we can test/demonstrate that behavior.
const appRoutes: Routes = [
  {
    path: '',
    component: MainNavComponent
  }
];

@NgModule({
  declarations: [MainNavComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    HttpClientModule,
    MatSidenavModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    NavigationModule.forRoot({
      baseServicesURL: environment.baseServicesURL,
      baseSiteURL: environment.baseSiteURL
    }),
    RouterModule.forRoot(appRoutes, { enableTracing: false })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: JwtInterceptorService,
      multi: true
    }
  ],
  bootstrap: [MainNavComponent]
})
export class AppModule {}
