import { Component, OnInit } from '@angular/core';
import {
  INavigationState,
  INavigationStateChange,
  createDefaultNavigationState,
  alterNavigationState
} from '@atonix/atx-navigation';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent {
  navState: INavigationState;

  title = 'rig';

  constructor() {
    this.navState = createDefaultNavigationState({
      getDataInComponent: true,
      navPaneState: 'hidden',
      appName: ''
    });
  }

  public onNavStateChange(change: INavigationStateChange) {
    this.navState = alterNavigationState(this.navState, change);
  }
}
